<script lang="ts" setup>
import { useQuery } from '@tanstack/vue-query'
import { DateTime } from 'luxon'
import { joinURL } from 'ufo'

import { PUBLIC_IMAGES_BUCKET_NAME } from '~/utils/constants'

const props = defineProps<{
  selectable?: boolean
  hideUploadButton?: boolean
}>()

const emit = defineEmits<{
  imageSelected: [{ alt: string; src: string }]
}>()
const supabase = useSupabase()
const user = useUser()
const userIsAdmin = computed(() => user.value.user_metadata.role === 'admin')

const folder = refEffect(() => (userIsAdmin.value ? '' : user.value?.id))
const pageRef = refEffect(() => (folder.value, 1))
const itemsPerPage = 50
const page = computed({
  get() {
    return pageRef.value
  },
  set(newPage) {
    pageRef.value = Math.max(newPage, 1)
  },
})

const { data: userEmails } = useQuery({
  queryKey: ['supabase-user-emails'],
  enabled: userIsAdmin,
  queryFn: async () => {
    return await useTRPC().users.getUserEmails.query()
  },
})

const { data: imageData, refetch: refetchImageObjects } = useQuery({
  queryKey: [
    'supabase-storage-list',
    PUBLIC_IMAGES_BUCKET_NAME,
    folder,
    page,
    itemsPerPage,
  ] as const,
  queryFn: async ({ queryKey }) => {
    const [imageFiles, hasNextPage] = await Promise.all([
      supabase.storage
        .from(PUBLIC_IMAGES_BUCKET_NAME)
        .list(queryKey[2], {
          limit: itemsPerPage,
          offset: (queryKey[3] - 1) * itemsPerPage,
          sortBy: {
            column: 'created_at',
            order: 'desc',
          },
        })
        .then((res) => {
          if (res.error) throw res.error
          return res.data
        }),
      supabase.storage
        .from(PUBLIC_IMAGES_BUCKET_NAME)
        .list(queryKey[2], {
          limit: 1,
          offset: queryKey[3] * itemsPerPage,
          sortBy: {
            column: 'created_at',
            order: 'desc',
          },
        })
        .then((res) => {
          if (res.error) return false
          return res.data.length > 0
        }),
    ])

    return {
      images: imageFiles,
      hasNextPage,
    }
  },
})

const selectedImageId = ref<string>()

function getUrlFromImageObject(image: { name: string }) {
  return supabase.storage
    .from(PUBLIC_IMAGES_BUCKET_NAME)
    .getPublicUrl(`${folder.value}/${image.name}`).data.publicUrl
}

function handleItemClick(image: {
  id: string
  name: string
  metadata: Record<string, any> | null
}) {
  if (image.metadata === null) {
    folder.value = joinURL(folder.value, image.name)
    return
  }

  if (props.selectable) {
    emit('imageSelected', {
      src: getUrlFromImageObject(image),
      alt: '',
    })
  } else {
    selectedImageId.value = image.id === selectedImageId.value ? undefined : image.id
  }
}

const folderBreadcrumb = computed(() => {
  const breadcrumb: { label: string; icon?: string }[] = folder.value
    .split('/')
    .filter(Boolean)
    .map((i) => ({ label: i }))

  breadcrumb.unshift({ label: 'Hauptverzeichnis', icon: 'i-ph-house' })

  return breadcrumb
})
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex gap-6">
      <AlMultiImageUploadButton
        v-if="!hideUploadButton"
        :folder-name="user.id"
        @upload-complete="() => refetchImageObjects()"
      />
      <div v-if="page !== 1 || imageData?.hasNextPage" class="flex items-center gap-2">
        <UButton
          icon="i-ph-caret-left-fill"
          variant="soft"
          :class="page === 1 && 'invisible'"
          @click="() => page--"
        />
        <p class="text-lg text-stone-500">Seite {{ page }}</p>
        <UButton
          v-if="imageData?.hasNextPage"
          icon="i-ph-caret-right-fill"
          variant="soft"
          @click="() => page++"
        />
      </div>
    </div>
    <UBreadcrumb divider="/" :links="folderBreadcrumb">
      <template #default="{ link, index }">
        <button
          class="hover:underline"
          @click="() => (folder = folder.split('/').slice(0, index).join('/'))"
        >
          {{ link.label }}
        </button>
      </template>
    </UBreadcrumb>
    <div class="grid grid-cols-3 gap-8 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6">
      <button
        v-for="object of imageData?.images ?? []"
        :key="object.id"
        class="flex flex-col gap-1"
        @click="() => handleItemClick(object)"
      >
        <img
          v-if="object.metadata?.mimetype?.startsWith('image/')"
          :src="getUrlFromImageObject(object)"
          :class="
            selectedImageId === object.id
              ? 'fixed inset-0 aspect-auto h-full bg-stone-800/70 p-16'
              : 'bg-stone-200'
          "
          class="block aspect-square w-full rounded-md object-contain"
        />
        <div
          v-else-if="object.metadata === null"
          class="flex aspect-square w-full items-center justify-center rounded-md border"
        >
          <UIcon name="i-ph-folder" class="size-12 text-stone-600 lg:size-24" />
        </div>
        <div class="line-clamp-2 w-full break-words text-center text-sm text-stone-500">
          {{ folder === '' ? userEmails?.[object.name] : formatStorageObjectName(object.name) }}
        </div>
        <div class="w-full text-center text-xs text-stone-400">
          {{ object.created_at ? formatDateTime(DateTime.fromISO(object.created_at)) : '' }}
        </div>
      </button>
    </div>
  </div>
</template>
