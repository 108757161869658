<script lang="ts" setup>
defineProps<{
  folderName: string
}>()

const emit = defineEmits<{
  uploadComplete: []
}>()

const uploadModalOpen = ref(false)
</script>

<template>
  <div>
    <UButton
      icon="i-ph-upload-simple-bold"
      class="self-start"
      label="Bilder hochladen"
      @click="() => (uploadModalOpen = true)"
    />
    <AlMultiImageUploadModal
      v-model:open="uploadModalOpen"
      :bucket-name="PUBLIC_IMAGES_BUCKET_NAME"
      :folder-name="folderName"
      @upload-complete="() => emit('uploadComplete')"
    />
  </div>
</template>
