<script lang="ts" setup>
import { useQueryClient } from '@tanstack/vue-query'
import Compressor from '@uppy/compressor'
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import German from '@uppy/locales/lib/de_DE'
import Tus from '@uppy/tus'
import { nanoid } from 'nanoid'

import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'
import { PUBLIC_IMAGES_BUCKET_NAME } from '~/utils/constants'
import { removeFileExtension } from '~/utils/util'

import type { CompressorOptions } from '@uppy/compressor'

const props = defineProps<{
  bucketName: typeof PUBLIC_IMAGES_BUCKET_NAME
  folderName?: string
  open: boolean
}>()

const emit = defineEmits<{
  uploadComplete: []
}>()
const supabase = useSupabase()
const queryClient = useQueryClient()
const user = useUser()
const config = useRuntimeConfig()

const isOpen = useVModel(props, 'open')

const uploadUrl = `${config.public.supabase.url}/storage/v1/upload/resumable`

const uppyElementRef = ref<HTMLDivElement>()
const uppy = ref<Uppy>()
watch(
  uppyElementRef,
  () => {
    if (!uppyElementRef.value) return
    uppy.value = new Uppy({
      debug: import.meta.dev,
      locale: German,
      restrictions: {
        allowedFileTypes: ['image/png', 'image/webp', 'image/gif', 'image/jpeg', 'image/svg+xml'],
        maxFileSize: 5 * 1024 * 1024,
        minNumberOfFiles: 1,
      },
      allowMultipleUploadBatches: false,
    })
      .use(Dashboard, {
        target: uppyElementRef.value,
        proudlyDisplayPoweredByUppy: false,
        autoOpenFileEditor: true,
        metaFields: [
          {
            id: 'alt',
            name: 'Alt-Text',
            placeholder: 'Alt-Text',
          },
        ],
        closeAfterFinish: true,
        lazy: true,
      })
      .use(Compressor, {
        mimeType: 'image/webp',
        locale: {
          strings: {
            compressedX: '%{size} durch Komprimierung gespart',
            compressingImages: 'Bilder werden komprimiert',
          },
        },
      } as CompressorOptions & { mimeType: string })
      .use(Tus, {
        endpoint: uploadUrl,
        chunkSize: 6 * 1024 * 1024,
        allowedMetaFields: ['bucketName', 'objectName', 'contentType', 'cacheControl'],
      })
      .on('upload', () => {
        void (async () => {
          const tus = uppy.value?.getPlugin('Tus')
          if (!tus) return

          const {
            data: { session },
          } = await supabase.auth.getSession()
          if (!session) return

          tus.setOptions({
            headers: {
              Authorization: `Bearer ${session.access_token}`,
            },
          })
        })()
      })
      .on('dashboard:modal-closed', () => {
        isOpen.value = false
      })
      .on('preprocess-complete', (file) => {
        if (!file) throw new Error('No file')
        uppy.value?.setFileMeta(file.id, {
          ...file.meta,
          bucketName: props.bucketName,
          objectName: `${props.folderName ?? nanoid()}/${slugify(
            `${removeFileExtension(file.name)}-${nanoid(5)}`,
          )}`,
          contentType: file.type,
        })
      })
      .on('complete', () => {
        emit('uploadComplete')
        void queryClient.invalidateQueries({
          queryKey: ['supabase-storage-list', PUBLIC_IMAGES_BUCKET_NAME, user.value.id],
        })
      })
  },
  {
    immediate: true,
  },
)

watch([isOpen, uppy], ([newOpen, newUppy]) => {
  if (!newUppy) return

  if (newOpen) {
    // @ts-expect-error openModal does not exist on Dashboard??
    newUppy.getPlugin('Dashboard')?.openModal()
  } else {
    // @ts-expect-error closeModal does not exist on Dashboard??
    newUppy.getPlugin('Dashboard')?.closeModal()
  }
})
</script>

<template>
  <div ref="uppyElementRef" />
</template>
