import { useRouteParams } from '@vueuse/router'

import type { RouteParamValueRaw } from 'vue-router'

import { onBeforeRouteLeave } from '#app'

export function refEffect<T>(getter: MaybeRefOrGetter<T>) {
  const getterRef = toRef(getter)
  const __ref = ref(getterRef.value as T)
  const _ref = __ref as typeof __ref & { reset: () => void }

  watchEffect(() => {
    // eslint-disable-next-line ts/no-unsafe-assignment
    _ref.value = getterRef.value
  })

  _ref.reset = () => {
    // eslint-disable-next-line ts/no-unsafe-assignment
    _ref.value = getterRef.value
  }

  return _ref
}

export function useRouteParamString<T extends RouteParamValueRaw>(
  paramName: string,
  displayName: string,
  defaultValue?: Parameters<typeof useRouteParams<T>>[1],
  options?: Parameters<typeof useRouteParams<T>>[2],
) {
  const param = useRouteParams<T>(paramName, defaultValue, options)

  const paramComputed = computed({
    get: () => {
      if (typeof param.value !== 'string') throw new Error(`${displayName} must be string`)

      return param.value
    },
    set: (value) => {
      param.value = value
    },
  })

  onBeforeRouteLeave((_, __, next) => {
    paramComputed.effect.stop()
    next()
  })

  return paramComputed
}
